import bugsnag from '@bugsnag/js'

// NOTE: jsのシンタックスエラーも通知させる為にエントリーポイントを別にしている

// NOTE: テスト実行時の notifyReleaseStages 警告が邪魔だからログ無効化
const options = process.env.RAILS_ENV === 'test' ? { logger: null } : {}

const bugsnagClient = bugsnag({
  apiKey: 'b81b7c8702b52e28478ce371d9965a9a',
  releaseStage: process.env.RAILS_ENV,
  notifyReleaseStages: ['staging', 'production'],
  ...options,
})
